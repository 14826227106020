<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.type" placeholder="请选择类型" clearable size="small">
          <el-option :key="1" label="公众号" :value="1"></el-option>
          <el-option :key="2" label="小程序" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
          :data="tableData"
          ref="rfTable"
          style="width: 100%"
          size="mini"
          @selection-change="handleSelectionChange">
      <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="title"
          min-width="160"
          label="商户名称">
      </el-table-column>
      <el-table-column
          prop="appid"
          label="appid"
          width="150">
      </el-table-column>
      <el-table-column
          prop="type"
          label="类型"
          width="130">
        <template slot-scope="scope">
          <div v-if="scope.row.type==1">
            {{ scope.row.verify_type==-1?'未认证':'已认证' }}
            {{ scope.row.service_type==2?'服务号':'订阅号' }}
          </div>
          <div v-if="scope.row.type==2">小程序</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="nick_name"
          label="昵称"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="head_img"
          label="头像"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-avatar :src="scope.row.head_img" shape="square"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
          prop="qrcode"
          label="二维码"
          align="center"
          width="160">
        <template slot-scope="scope">
          <el-avatar :src="scope.row.qrcode" shape="square"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
          prop="user_name"
          label="账号GID"
          align="center"
          width="160">
      </el-table-column>
      <el-table-column
          prop="signature"
          label="介绍"
          align="center"
          width="160">
      </el-table-column>
      <el-table-column
          prop="principal_name"
          label="主体"
          align="center"
          width="160">
      </el-table-column>
      <el-table-column
          prop="alias "
          label="微信号"
          align="center"
          width="160">
      </el-table-column>
      <el-table-column
          prop="agreement_status"
          label="协议状态"
          align="center"
          width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.agreement_status==0">待审核</span>
          <span v-if="scope.row.agreement_status==1">审核中</span>
          <span v-if="scope.row.agreement_status==2">已通过</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="location_status"
          label="接口状态"
          align="center"
          width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.location_status==0">待审核</span>
          <span v-if="scope.row.location_status==1">审核中</span>
          <span v-if="scope.row.location_status==2">已通过</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="160">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="showQrcode(scope.row)" v-if="scope.row.type==2">体验码</el-button>
          <el-button type="text" size="small" @click="setTester(scope.row)" v-if="scope.row.type==2">体验者</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 30, 40]"
      :total="search.total">
    </el-pagination>
    <el-dialog title="管理体验者" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" custom-class="dialog" width="740px">
      <tester-list :id="showId"></tester-list>
    </el-dialog>
    <el-dialog title="体验码" :visible.sync="dialogQrcodeVisible" v-if="dialogQrcodeVisible" custom-class="dialog" width="340px">
      <img :src="qrcode" width="300" />
    </el-dialog>
  </div>
</template>
<script>

import TesterList from "@/components/TesterList";
export default {
  components: {
    TesterList
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        type: ''
      },
      tableData: [],
      dialogFormVisible: false,
      dialogQrcodeVisible: false,
      showId: '',
      qrcode: ''
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    setTester(item) {
      this.showId = item.id
      this.dialogFormVisible = true
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        type: that.search.type
      }
      this.$api.wechat.wechatIndex(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    showQrcode(item) {
      this.$api.wechat.wechatExperienceQrcode({id: item.id}, res => {
        if(res.errcode==0) {
          this.qrcode = res.data.qrcode
          this.dialogQrcodeVisible = true
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
